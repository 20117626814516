/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-vars */

import auth from "../../../services/authService";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import http from "../../../services/httpService";
import UseHandleModal from "../../../modal/UseHandleModal1";
import LoginHeader from "../../../components/loginHeader";
import Navbar from "../../../components/navbar";
import BackButton from "../../../components/backButton";
import { Formik, Form, Field } from "formik";
import { useSelector } from "react-redux";


const DisputeUploadReportPage = (props) => {
  const navigate = useNavigate();
  const email = auth.getUser().UserLogin.email;
  const theme = useSelector((state) => state.theme.theme);

  const [usersData, setUsersData] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [cases, setCases] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [noticeTimeStamp, setNoticeTimeStamp] = useState("");
  const [noticeInfos, setNoticeInfo] = useState([]);

  const { handleModal2, showDarkThemeModal, showLightThemeModal } = UseHandleModal();

  const resetPage = () => {
    setPage(1);
  };

  const handleFilterReset = () => {
    setNoticeTimeStamp("");
    resetPage();
  };

  const fetchData = async () => {
    try {
      let response = await http.post2("/getNoticeReports", {
        email,
        timeStamp: noticeTimeStamp,
        page,
        perPage,
        search
      });
      console.log(response)
      if (response.status === 200) {
        setUsersData(response.data.data);
        setCases(response.data.totalCases);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (err) {
      console.error("Error fetching report CSV data:", err);
      handleModal2("Error fetching data");
    }
  };

  const fetchData3 = async () => {
    try {
      const response = await http.post2("/getNotices", {
        email,
      });
      if (response.status !== 400) {
        setNoticeInfo(response.data);
      } else {
        throw new Error("Failed to fetch notice info");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      handleModal2("Error fetching notice info");
    }
  };

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  useEffect(() => {
    fetchData();
  }, [page, perPage, search, noticeTimeStamp]);

  useEffect(() => {
    fetchData3();
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const updatePayment = async (loanId, paymentValue, timeStamp) => {
    try {
      const response = await http.post2("/updatePayment", {
        loanId,
        payment: paymentValue,
        timeStamp,
      });
      if (response.status === 200) {
        theme ? showLightThemeModal("Payment updated successfully") : showDarkThemeModal("Payment updated successfully");
      } else {
        theme ? showLightThemeModal("Failed to update payment") : showDarkThemeModal("Failed to update payment");
      }
    } catch (error) {
      console.error("Error updating payment:", error);
      handleModal2("Error updating payment");
    }
  };

  const handlePaymentChange = (loanId, value) => {
    const updatedUsersData = usersData.map((user) =>
      user.loanId === loanId ? { ...user, payment: value } : user
    );
    setUsersData(updatedUsersData);
  };

  const fetchCurrentPayment = async (loanId, timeStamp) => {
    try {
      const response = await http.post2("/getPaymentInfo", {
        loanId,
        timeStamp,
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Failed to fetch current payment");
      }
    } catch (error) {
      console.error("Error fetching current payment:", error);
      handleModal2("Error fetching current payment");
      return null;
    }
  };

  const handlePaymentBlur = async (loanId, value, timeStamp) => {
    const currentPayment = await fetchCurrentPayment(loanId, timeStamp);
    if (currentPayment !== null && currentPayment === value) {
      theme
        ? showLightThemeModal("Payment info is the same, no update needed.")
        : showDarkThemeModal("Payment info is the same, no update needed.");
    } else {
      updatePayment(loanId, value, timeStamp);
    }
  };

  const handleLoanClick = async (loanId) => {
    navigate(`/DisputeUserProfile?loanId=${loanId}`);
  };


  return (
    <div className="main-wrapper">
      <div className="container-fluid">
        <div className="row">
          {/* Left Side */}
          <div className="col-xl-3">

            <div className="left-wrapper">
              <Navbar></Navbar>
              <BackButton link="/bulkUpload"></BackButton>
              <div className="" id="collapseExample">
                <div className="data-filter">

                  {/* Search Bar */}
                  <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      id="floatingSelect"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      aria-label="Floating label select example"
                    />
                    <label>
                      Search By Borrower Name, Loan ID, Agreement No
                    </label>
                  </div>

                  {/* Select Sheet Name */}
                  <div className="form-floating mb-3">
                    <select
                      className="form-select"
                      id="floatingSelect"
                      value={noticeTimeStamp}
                      onChange={(e) => setNoticeTimeStamp(e.target.value)}
                      aria-label="Floating label select example"
                    >
                      <option value="">All</option>
                      {noticeInfos.length > 0
                        ? noticeInfos.map((noticeItem, index) => (
                          <option
                            style={{ justifyContent: "flex-start" }}
                            key={index}
                            value={noticeItem.timeStamp}
                          >
                            {`${noticeItem.documentId}-${noticeItem.noticeType}`}
                          </option>
                        ))
                        : ""}
                    </select>
                    <label>Select Sheet Name</label>
                  </div>

                  {/* Reset Filter */}
                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={handleFilterReset}
                    >
                      Reset Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>

          {/* Right Side */}
          <div className="col-xl-9">
            <LoginHeader />
            <div className="right-wrapper mt-3">
              <div className="conciliation-application-form">
                <form id="regForm" action="#">
                  <div className="col-lg-12">
                    <div className="card card-new h-100">
                      {/* Above Table Details  */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h2 className="mb-3">Reports</h2>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {/* Cases Details */}
                          <div className="d-flex">
                            <h2
                              style={{ marginRight: "2px", marginTop: "5px" }}
                            >
                              <span style={{ fontSize: "12px" }}>Cases </span>(
                              {cases})
                            </h2>
                          </div>

                        </div>
                      </div>
                      {/* Table Details */}
                      <div className="col-lg-12">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">Date</th>
                              <th scope="col">LoanID</th>
                              <th scope="col">Borrower</th>
                              <th scope="col">City & State</th>
                              <th scope="col">Process</th>
                              <th scope="col">Level</th>
                              <th scope="col">Statuses</th>
                              <th scope="col">Payment</th>
                            </tr>
                          </thead>
                          <tbody>
                            {usersData.length > 0
                              ? usersData.map((item, index) => {
                                return (
                                  <tr key={item._id}>
                                    <td data-label="Date">
                                      {formatTimestamp(item.timeStamp)}
                                    </td>
                                    <td
                                      data-label="Loan ID"
                                      onClick={() => {
                                        handleLoanClick(item.loanId);
                                      }}
                                      style={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {item.loanId}
                                    </td>
                                    <td data-label="Borrower/Company">
                                      {item?.displayName}
                                    </td>
                                    <td data-label="City & State">
                                      {item?.city && item?.state ? `${item.city} - ${item.state}` : "-"}
                                    </td>
                                    <td data-label="Process">
                                      {item?.process || "-"}
                                    </td>
                                    <td data-label="Level">
                                      {item?.level || "-"}
                                    </td>
                                    <td data-label="Statuses">
                                      {item?.communication || "-"}
                                    </td>
                                    <td data-label="Payment">
                                      <Formik>
                                        <Form>
                                          <Field
                                            type="text"
                                            id="payment"
                                            name="payment"
                                            className="form-control"
                                            value={item.payment || ""}
                                            onChange={(e) => handlePaymentChange(item.loanId, e.target.value)}
                                            onBlur={async (e) => {

                                              await handlePaymentBlur(item.loanId, e.target.value, item.timeStamp);
                                            }}
                                            onKeyDown={(e) => {
                                              if (e.key === "Enter") {
                                                e.preventDefault();
                                              }
                                            }}
                                            placeholder="Payment Info"
                                          />
                                        </Form>
                                      </Formik>
                                    </td>


                                  </tr>
                                );
                              })
                              : (
                                <tr>
                                  <td colSpan="8" className="text-center">
                                    No records found.
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      </div>
                      {/* Pagination Div */}
                      <div className="row">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                          <div className="data-pagi float-lg-end mt-lg-0 mt-2">
                            <select
                              className="form-control"
                              id="floatingSelect"
                              value={perPage ? perPage : 10}
                              onChange={(e) => {
                                setPerPage(e.target.value);
                              }}
                              aria-label="Floating label select example"
                            >
                              <option value={10}>10</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                            <div
                              className="btn-toolbar"
                              role="toolbar"
                              aria-label="Toolbar with button groups"
                            >
                              <div
                                className="btn-group me-1"
                                role="group"
                                aria-label="First group"
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => setPage(+page - 1)}
                                  disabled={page <= 1}
                                >
                                  <i className="bi bi-chevron-left text-white"></i>
                                </button>
                              </div>
                              <div
                                className="btn-group me-1"
                                role="group"
                                aria-label="Second group"
                              >
                                <button
                                  type="button"
                                  className="btn text-primary"
                                >
                                  {page}
                                </button>
                              </div>
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Third group"
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled={usersData.length < +perPage}
                                  onClick={() => setPage(+page + 1)}
                                >
                                  <i className="bi bi-chevron-right text-white"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DisputeUploadReportPage;
