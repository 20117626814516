/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { Tooltip } from "bootstrap";
import UseHandleModal from "./UseHandleModal";

const HeaderMappingModal = ({
  show,
  onHide,
  csvHeaders,
  requiredHeaders,
  onMappingComplete,
  tooltipTexts,
  initialMapping,
  fetchDropdownValues,
}) => {
  const [mapping, setMapping] = useState({});
  const [customDropdowns, setCustomDropdowns] = useState([]);
  const [showCustomHeaderModal, setShowCustomHeaderModal] = useState(false);
  const completeButtonRef = useRef(null);
  const { handleModal2, handleModal3 } = UseHandleModal();
  const [availableHeaders] = useState([
    "Borrower Email",
    "CoBorrower Email",
    "Name of Second CoBorrower",
    "EMI Start Date",
    "EMI End Date",
    "Interest",
    "RC Number",
    "CIN Number",
    "GST Number",
    "Vehicle Number",
    "Driving Licence Number",
    "Loan Type",
    "EMI",
    "Total Loan",
    "EMI Start",
    "EMI End",
    "Seat",
    "Bank Account Number",
    "IFSC Code",
    "Dispute Resolution Paper ID",
    "Associate Name",
    "Ref Number",
    "Case ID",
    "Principle Outstanding",
    "Maximum Settlement Amount",
    "Minimum Settlement Amount",
    "ClaimantSide For Coordination",
    "ClaimantSide For Coordination Contact",
    "Zone",
    "DPD",
    "Notice Stage",
  ]);


  useEffect(() => {
    if (show) {
      const tooltipTriggerList = document.querySelectorAll(
        '[data-bs-toggle="tooltip"]'
      );
      tooltipTriggerList.forEach((tooltipTriggerEl) => {
        new Tooltip(tooltipTriggerEl);
      });

      if (completeButtonRef.current) {
        new Tooltip(completeButtonRef.current);
      }
    }
  }, [show]);

  useEffect(() => {
    setMapping(initialMapping);
  }, [initialMapping]);


  const handleSelectChange = (e, requiredHeader) => {
    setMapping((prevMapping) => ({
      ...prevMapping,
      [requiredHeader]: e.target.value,
    }));
  };


  const handleAddCustomDropdown = (headerName) => {
    const isHeaderAlreadySelected =
      requiredHeaders.includes(headerName) ||
      customDropdowns.some((d) => d.name === headerName);

    if (isHeaderAlreadySelected) {
      handleModal2(
        `The header "${headerName}" is already selected or exists as a custom dropdown. Please choose a different header.`
      );
      return;
    }

    setCustomDropdowns((prevCustomDropdowns) => [
      ...prevCustomDropdowns,
      { id: prevCustomDropdowns.length + 1, name: headerName },
    ]);
    setShowCustomHeaderModal(false);
  };

  const handleCompleteMapping = () => {
    onMappingComplete(mapping, customDropdowns);
    onHide();
  };

  const isMappingComplete = requiredHeaders.every(
    (header) => mapping[header] && mapping[header] !== ""
  );

  return (
    <>
      {/* Main Modal */}
      <div
        className={`modal fade ${show ? "show d-block" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: show ? "block" : "none" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Map Your CSV Headers</h5>
              <button
                type="button"
                className="btn-close"
                onClick={onHide}
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                 
                  {requiredHeaders.map((requiredHeader) => (
                    <div className="col-md-6 mb-3" key={requiredHeader}>
                      <label
                        className="form-label"
                        data-bs-toggle="tooltip"
                        title={
                          tooltipTexts[requiredHeader] ||
                          `Tooltip for ${requiredHeader}`
                        }
                      >
                        {requiredHeader}
                      </label>
                      <select
                        className="form-select"
                        value={mapping[requiredHeader] || ""}
                        onChange={(e) => handleSelectChange(e, requiredHeader)}
                      >
                        <option value="">Select a header</option>
                        {csvHeaders.map((csvHeader) => (
                          <option key={csvHeader} value={csvHeader}>
                            {csvHeader}
                          </option>
                        ))}
                      </select>
                      {!mapping[requiredHeader] && (
                        <div className="text-danger mt-1">
                          This field is required.
                        </div>
                      )}
                    </div>
                  ))}
                  {customDropdowns.map((dropdown) => (
                    <div className="col-md-6 mb-3" key={dropdown.id}>
                      <label className="form-label">{dropdown.name}</label>
                      <select
                        className="form-select"
                        value={mapping[dropdown.name] || ""}
                        onChange={(e) => handleSelectChange(e, dropdown.name)}
                      >
                        <option value="">Select a header</option>
                        {csvHeaders.map((csvHeader) => (
                          <option key={csvHeader} value={csvHeader}>
                            {csvHeader}
                          </option>
                        ))}
                      </select>
                    </div>
                  ))}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setShowCustomHeaderModal(true)}
              >
                Add Additional Header
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onHide}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleCompleteMapping}
                disabled={!isMappingComplete}
                ref={completeButtonRef}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={
                  !isMappingComplete
                    ? "Please select a value for all required headers."
                    : ""
                }
              >
                Complete Mapping
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Custom Header Modal */}
      <div
        className={`modal fade ${showCustomHeaderModal ? "show d-block" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: showCustomHeaderModal ? "block" : "none" }}
      >
        <div
          className="modal-dialog modal-sm modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select Additional Header</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowCustomHeaderModal(false)}
              ></button>
            </div>
            <div
              className="modal-body"
              style={{ maxHeight: "400px", overflowY: "auto" }}
            >
              <ul className="list-group">
                {availableHeaders.map((header, index) => (
                  <li
                    key={header}
                    className={`d-flex justify-content-between align-items-center border-bottom mb-3 pb-2 ${
                      index === availableHeaders.length - 1 ? "border-0" : ""
                    }`}
                  >
                    {header}
                    <button
                      className="btn btn-primary btn-sm custom-btn-click-effect"
                      onClick={() => handleAddCustomDropdown(header)}
                    >
                      +
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setShowCustomHeaderModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderMappingModal;
