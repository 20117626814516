import {createSlice, current} from "@reduxjs/toolkit";

const initialState={
    data:{},
}

export const dashboardSlice=createSlice({
    name:"dashboardSlice",
    initialState,
    reducers:{
        setData:(state,action) => {
            state.data=action.payload
        }
    }
});

export const {setData}=dashboardSlice.actions;

export default dashboardSlice.reducer;