
import {configureStore} from '@reduxjs/toolkit';
import themeReducer from "../features/theme/themeSlice";
import fontReducer from "../features/font/fontSlice";
import stageReducer from "../features/verificationStage/verificationSlice"
import langReducer from "../features/language/langSlice"
import emailReducer from "../features/email/emailSlice"
import userNameReducer from "../features/userName/userNameSlice";
import dashboardSlice from '../features/dashboardSlice/dashboardSlice';
import SingleDispute from '../pages/singleDispute/singleDispute';

const rootReducer = {
    theme: themeReducer,
    font: fontReducer,
    verificationStage:stageReducer,
    lang:langReducer,
    email:emailReducer,
    userName:userNameReducer,
    dashboardSlice:dashboardSlice,
    SingleDispute:SingleDispute
  };

export const store=configureStore({
    reducer:rootReducer
});