import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './PaymentHistory.css';

const FillingCalender = (props) => {
    const gstFiling=props?.gstFiling;
    let count = 0;
    console.log(gstFiling)
  const initialYear = '2024'; // You can set this dynamically if needed
  const [currentYear, setCurrentYear] = useState(initialYear);
  const [combinedFiling, setCombinedFiling] = useState([]);

  const monthsMap = {
    "Jan": "01",
    "Feb": "02",
    "Mar": "03",
    "Apr": "04",
    "May": "05",
    "Jun": "06",
    "Jul": "07",
    "Aug": "08",
    "Sep": "09",
    "Oct": "10",
    "Nov": "11",
    "Dec": "12"
  };

  const getReturnStatusClass = (count) => {
    if (count == 2) {
      return "tick";
    } else if (count !== "") {
      return "bg-warning";
    }
    return "";
  };

  const renderCalendar = (data) => {
    return (
      <div className="year-section">
        <div className="header">
          <FontAwesomeIcon icon={faArrowLeft} onClick={() => changeYear(-1)} />
          <h5>GST Filing History {currentYear}</h5>
          <FontAwesomeIcon icon={faArrowRight} onClick={() => changeYear(1)} />
        </div>
        <div className="months-grid">
          {[ 'Jan', 'Feb', 'Mar','Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map(month => {

            const filings = data.filter(item => {
              const [day, monthPart, year] = item?.dof?.split('-');
              return monthsMap[month] === monthPart && year === currentYear;
            });
            // const filings = data.filter(item => {
            //   // Ensure `dof` exists and is in the correct format
            //   if (!item?.dof || item.dof.split('-').length !== 3) {
            //     return false; // Skip this item if `dof` is missing or incorrectly formatted
            //   }
            
            //   const [day, monthPart, year] = item.dof.split('-');
            //   return monthsMap[monthPart] === monthPart && year === currentYear;
            // });
            
            const filedCount = filings.reduce((acc, filing) => {
                    if (filing.status === 'Filed') {
                      return acc + 1;
                    }
                    return acc;
                  }, 0);

            console.log(filedCount,filings)

            return (
              <div key={month} className="month d-flex">
              <h5>{month}</h5>
              {filings.length > 0 ? ( 
                  <div className={`month ${getReturnStatusClass(filedCount)} mb-2`}>
                    {filedCount > 1 ? '✓' : 'Not Filed'}
                  </div>
              ) : (
                ''
              )}
            </div>
          );
        })}
      </div>
    </div>
    );
  };
  const getYearsFromGSTData = () => {
    const years = combinedFiling?.map(item => {
      const year = item.dof.split('-')[2];
      return year;
    });
    return [...new Set(years)];
  };

  const changeYear = (direction) => {
    const years = getYearsFromGSTData().reverse();
    const currentIndex = years.indexOf(currentYear);
    const newIndex = (currentIndex + direction + years.length) % years.length;
    setCurrentYear(years[newIndex]);
  };
  useEffect(() => {
    setCombinedFiling(gstFiling);
  }, [gstFiling]);
  return (
    <div className="container">
      <div className="payment-history">
        {renderCalendar(gstFiling)}
      </div>
    </div>
  );
};

export default FillingCalender;
