import { useState } from "react";
import PrevNextButton from "../../../components/prevNextButton";

const SingleDisputeForm = () => {
  // const [formData, setFormData] = useState({
  //   loanId: "",
  //   borrowerName: "",
  //   borrowerPhone: "",
  //   borrowerEmail: "",
  //   borrowerAddress: "",
  //   borrowerPanNumber: "",
  //   coBorrowerName: "",
  //   coBorrowerPhone: "",
  //   coBorrowerEmail: "",
  //   coBorrowerAddress: "",
  //   coBorrowerPanNumber:"",
  //   date: "",
  //   noticeType: "",
  //   claimantCompany: "",
  //   claimantCompanyAddress: "",
  //   claimAmount: "",
  //   loanType: "",
  //   interest: "",
  //   gstNumber: "",
  //   cin: "",
  //   vehicleNo: "",
  //   rcNo: "",
  //   drivingLicenceNo: "",
  // });
  const [cardIndex, setCardIndex] = useState(0);
  const [cardIndexLength, setCardIndexLength] = useState(4);
  const [formData, setFormData] = useState({
    loanID: "DFD32322323343L",
    loanType: "Business Loan",
    borrowerDetails: [
      {
        name: "Balram Gupta",
        phone: [6395641861, 9634725589],
        email: ["balramgupta489@gmail.com", "balram@privatecourt.in"],
        pan: "DCBPB7385F",
        Aadhaar: "674226641972",
      }
    ],
    coBorrowerDetails: [
      {
        name: "Krishna Gupta",
        phone: [6395641861, 9634724589],
        email: ["balramgupta489@gmail.com", "balram@privatecourt.in"],
        pan: "DCBPB7385F",
        Aadhaar: "674226641972",
      }
    ],
    claimDetails: {
      claimAmount: "12122",
      emiStart: "12/10/2024",
      emiEnd: "12/11/2024",
      interest: "25%",
    },
    vehicleLoan:{
      drivingLicenceNumber: "12122",
      rcNumber: "1234024",
      vehicleNumber: "12232024"
    }
  });

  const handlePrevNextButton = (type) => {
    console.log(type, cardIndex);
    type == "prev"
      ? setCardIndex((prev) => prev - 1)
      : setCardIndex((prev) => prev + 1);
    console.log(type, cardIndex);
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // handleModal3("Data Saved Successfully");
  };

  //   const handlePreview = () => {
  //     setShowPreview(true); // Toggle the preview
  //   };

  return (
    <div className="row">
      <div className="col-xl-6">
        <h2 className="dispute-heding">Hello User,</h2>
        <p className="dispute-para">
          Welcome to the Dispute Resolution Form filling section. To begin, we
          need some basic details about the dispute in question. Please provide
          accurate information in the fields on the right-hand side to ensure we
          can process your request efficiently.
        </p>
      </div>
      <div className="col-xl-6">
        <div className="right-wrapper mt-3">
          <div className="lr-box">
            <div className="lr-box-content">
              {cardIndex == 0 ? (
                <>
                  <h2>Dispute Details:</h2>
                  <p>
                    Please provide the details of your dispute and select the
                    dispute type to ensure we address your issue efficiently.
                  </p>
                  <div className="mb-3 mt-3">
                    <label className="form-label">Loan ID</label>
                    <input
                      className="mt-1 mb-1 form-control rounded"
                      type="text"
                      placeholder="Enter Loan ID"
                    />
                  </div>
                  <div className="mb-3 mt-3">
                    <label className="form-label">Loan Type</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option selected="" data-translate="ls-5">
                        Select Your Dispute List
                      </option>
                      <option value="2">
                        Non-payment of EMI: The borrower has missed scheduled
                        EMI payments as per the agreement.
                      </option>
                      <option value="2">
                        Loan Default: The borrower has defaulted on their loan,
                        triggering potential legal or recovery actions.
                      </option>
                      <option value="3">
                        Interest Rate Discrepancy: A disagreement over the
                        interest rate charged or applied on the loan.
                      </option>
                      <option value="4">
                        Agreement Dispute: Issues arising from the clauses or
                        terms outlined in the loan agreement.
                      </option>
                      <option value="5">
                        Penalty Charges Dispute: A dispute over penalty charges,
                        late fees, or other extra costs applied by the lender.
                      </option>
                      <option value="6">
                        Other: For disputes not covered by the options above,
                        please describe the issue in detail.
                      </option>
                    </select>
                  </div>
                </>
              ) : (
                ""
              )}
              {/* borrowerDetails */}
              {cardIndex > 0 && cardIndex <= formData.borrowerDetails.length ? (
                <>
                  <div className="lr-box-content">
                    <div className="mb-3 mt-3">
                      <label className="form-label">Name</label>
                      <input
                        className="mt-1 mb-1 form-control rounded"
                        type="text"
                        placeholder="Enter Name"
                      />
                    </div>
                    <div className="mb-3 mt-3">
                      <div className="d-flex justify-content-between">
                        <label className="form-label">Phone</label>
                        <i className="bi bi-plus-circle-fill js-add-btn js-add-phone-1"></i>
                      </div>
                      <input
                        className="mt-1 mb-1 form-control rounded"
                        type="tel"
                        placeholder="Enter Phone Number"
                      />
                      <div id="new-phone-field"></div>
                    </div>
                    <div className="mb-3 mt-3">
                      <div className="d-flex justify-content-between">
                        <label className="form-label">Email</label>
                        <i className="bi bi-plus-circle-fill js-add-btn js-add-email-1"></i>
                      </div>
                      <input
                        className="mt-1 mb-1 form-control rounded"
                        type="email"
                        placeholder="Enter Email Number"
                      />
                      <div id="new-email-field-two"></div>
                    </div>
                    <div className="mb-3 mt-3">
                      <label className="form-label">PAN Number</label>
                      <div className="d-flex flex-wrap pan-number-box-main mb-2">
                        <input
                          className="text-center text-uppercase form-control rounded"
                          type="text"
                          onkeypress="onlyAtoZ(event)"
                          id="first"
                          maxlength="1"
                        />
                        <input
                          className="text-center text-uppercase form-control rounded"
                          type="text"
                          onkeypress="onlyAtoZ(event)"
                          id="second"
                          maxlength="1"
                        />
                        <input
                          className="text-center text-uppercase form-control rounded"
                          type="text"
                          onkeypress="onlyAtoZ(event)"
                          id="third"
                          maxlength="1"
                        />
                        <input
                          className="text-center text-uppercase form-control rounded"
                          type="text"
                          onkeypress="onlyAtoZ(event)"
                          id="four"
                          maxlength="1"
                        />
                        <input
                          className="text-center text-uppercase form-control rounded"
                          type="text"
                          onkeypress="onlyAtoZ(event)"
                          id="five"
                          maxlength="1"
                        />
                        <input
                          className="text-center text-uppercase form-control rounded"
                          type="text"
                          id="six"
                          onkeypress="restrictInput(event)"
                          maxlength="1"
                        />
                        <input
                          className="text-center text-uppercase form-control rounded"
                          type="text"
                          id="seven"
                          onkeypress="restrictInput(event)"
                          maxlength="1"
                        />
                        <input
                          className="text-center text-uppercase form-control rounded"
                          type="text"
                          id="eight"
                          onkeypress="restrictInput(event)"
                          maxlength="1"
                        />
                        <input
                          class="text-center text-uppercase form-control rounded"
                          type="text"
                          id="nine"
                          onkeypress="restrictInput(event)"
                          maxlength="1"
                        />
                        <input
                          class="text-center text-uppercase form-control rounded"
                          type="text"
                          onkeypress="onlyAtoZ(event)"
                          id="ten"
                          maxlength="1"
                        />
                      </div>
                    </div>
                    <div class="mb-3 mt-3">
                      <label class="form-label">
                        Aadhaar Number (Optional)
                      </label>
                      <div class="otp-boxs otp-pan d-flex flex-wrap justify-content-center pan-number-box">
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="first"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="second"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="third"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="four"
                          maxlength="1"
                        />
                        <span class="aadhar-divider align-items-center">-</span>
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="five"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="six"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="seven"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="eight"
                          maxlength="1"
                        />
                        <span class="aadhar-divider align-items-center">-</span>
                        <h6 class="field-mid-text align-items-center">1234</h6>
                      </div>
                    </div>
                    <div class="mb-3 mt-3">
                      <label class="form-label">
                        Enter the Aadhaar OTP Here
                      </label>
                      <div class="otp-boxs d-flex flex-row justify-content-center pan-number-box">
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="nince"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="ten"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="ele"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="twe"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="thir"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="fourteen"
                          maxlength="1"
                        />
                      </div>
                    </div>
                    <div class="alert alert-primary" role="alert">
                      If there are additional individuals involved in this
                      dispute click the ‘Add Individuals’ button to provide
                      their details. Make sure you include their full name,
                      contact information, and PAN if applicable.
                      <br />
                      <button type="button" class="btn btn-primary mt-2">
                        Add Other
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {/* coBorrowerDetails */}
              {cardIndex > formData.borrowerDetails.length &&
              cardIndex <=
                formData.borrowerDetails.length +
                  formData.coBorrowerDetails.length ? (
                <>
                  <div class="lr-box-content">
                    <div class="mb-3 mt-3">
                      <label class="form-label">Name</label>
                      <input
                        class="mt-1 mb-1 form-control rounded"
                        type="text"
                        placeholder="Enter Name"
                      />
                    </div>
                    <div class="mb-3 mt-3">
                      <div class="d-flex justify-content-between">
                        <label class="form-label">Phone</label>
                        <i class="bi bi-plus-circle-fill js-add-btn js-add-phone-1"></i>
                      </div>
                      <input
                        class="mt-1 mb-1 form-control rounded"
                        type="tel"
                        placeholder="Enter Phone Number"
                      />
                      <div id="new-phone-field"></div>
                    </div>
                    <div class="mb-3 mt-3">
                      <div class="d-flex justify-content-between">
                        <label class="form-label">Email</label>
                        <i class="bi bi-plus-circle-fill js-add-btn js-add-email-1"></i>
                      </div>
                      <input
                        class="mt-1 mb-1 form-control rounded"
                        type="email"
                        placeholder="Enter Email Number"
                      />
                      <div id="new-email-field-two"></div>
                    </div>
                    <div class="mb-3 mt-3">
                      <label class="form-label">PAN Number</label>
                      <div class="d-flex flex-wrap pan-number-box-main mb-2">
                        <input
                          class="text-center text-uppercase form-control rounded"
                          type="text"
                          onkeypress="onlyAtoZ(event)"
                          id="first"
                          maxlength="1"
                        />
                        <input
                          class="text-center text-uppercase form-control rounded"
                          type="text"
                          onkeypress="onlyAtoZ(event)"
                          id="second"
                          maxlength="1"
                        />
                        <input
                          class="text-center text-uppercase form-control rounded"
                          type="text"
                          onkeypress="onlyAtoZ(event)"
                          id="third"
                          maxlength="1"
                        />
                        <input
                          class="text-center text-uppercase form-control rounded"
                          type="text"
                          onkeypress="onlyAtoZ(event)"
                          id="four"
                          maxlength="1"
                        />
                        <input
                          class="text-center text-uppercase form-control rounded"
                          type="text"
                          onkeypress="onlyAtoZ(event)"
                          id="five"
                          maxlength="1"
                        />
                        <input
                          class="text-center text-uppercase form-control rounded"
                          type="text"
                          id="six"
                          onkeypress="restrictInput(event)"
                          maxlength="1"
                        />
                        <input
                          class="text-center text-uppercase form-control rounded"
                          type="text"
                          id="seven"
                          onkeypress="restrictInput(event)"
                          maxlength="1"
                        />
                        <input
                          class="text-center text-uppercase form-control rounded"
                          type="text"
                          id="eight"
                          onkeypress="restrictInput(event)"
                          maxlength="1"
                        />
                        <input
                          class="text-center text-uppercase form-control rounded"
                          type="text"
                          id="nine"
                          onkeypress="restrictInput(event)"
                          maxlength="1"
                        />
                        <input
                          class="text-center text-uppercase form-control rounded"
                          type="text"
                          onkeypress="onlyAtoZ(event)"
                          id="ten"
                          maxlength="1"
                        />
                      </div>
                    </div>
                    <div class="mb-3 mt-3">
                      <label class="form-label">
                        Aadhaar Number (Optional)
                      </label>
                      <div class="otp-boxs otp-pan d-flex flex-wrap justify-content-center pan-number-box">
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="first"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="second"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="third"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="four"
                          maxlength="1"
                        />
                        <span class="aadhar-divider align-items-center">-</span>
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="five"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="six"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="seven"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="eight"
                          maxlength="1"
                        />
                        <span class="aadhar-divider align-items-center">-</span>
                        <h6 class="field-mid-text align-items-center">1234</h6>
                      </div>
                    </div>
                    <div class="mb-3 mt-3">
                      <label class="form-label">
                        Enter the Aadhaar OTP Here
                      </label>
                      <div class="otp-boxs d-flex flex-row justify-content-center pan-number-box">
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="nince"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="ten"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="ele"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="twe"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="thir"
                          maxlength="1"
                        />
                        <input
                          class="text-center form-control rounded"
                          type="text"
                          onkeypress="restrictInput(event)"
                          id="fourteen"
                          maxlength="1"
                        />
                      </div>
                    </div>
                    <div class="alert alert-primary" role="alert">
                      If there are additional individuals involved in this
                      dispute click the ‘Add Individuals’ button to provide
                      their details. Make sure you include their full name,
                      contact information, and PAN if applicable.
                      <br />
                      <button type="button" class="btn btn-primary mt-2">
                        Add Other
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {/* claimDetails */}
              {
                cardIndex==1+formData.borrowerDetails.length +
                formData.coBorrowerDetails.length ?<>
                    <div class="lr-box-content">
                          <div class="mb-3 mt-3">
                            <label class="form-label">Claim Amount</label>
                            <input class="mt-1 mb-1 form-control rounded" type="text" placeholder="Enter Claim Amount"/>
                          </div>
                          <div class="mb-3 mt-3">
                            <label class="form-label">EMI Start Date</label>
                            <input class="mt-1 mb-1 form-control rounded" type="date"/>
                          </div>
                          <div class="mb-3 mt-3">
                            <label class="form-label">EMI End Date</label>
                            <input class="mt-1 mb-1 form-control rounded" type="date"/>
                          </div>
                          <div class="mb-3 mt-3">
                            <label class="form-label">Interest Charged</label>
                            <input class="mt-1 mb-1 form-control rounded" type="text" placeholder="Interest Charged"/>
                          </div>
                          <div class="alert alert-primary" role="alert">
                            If there are additional claimants (other parties involved in filing this claim), click the ‘Add Claimant Details’ button to provide their information. Include their name, contact information, and role in the dispute.
                            <br/><button type="button" class="btn btn-primary mt-2" id="show-add-field-btn">Additional Claimant Details</button>
                          </div>
                          <div class="show-add-field">
                            <div class="mb-3 mt-3">
                              <label class="form-label">Name</label>
                              <input class="mt-1 mb-1 form-control rounded" type="text" placeholder="Enter Name"/>
                            </div>
                            <div class="mb-3 mt-3">
                              <div class="d-flex justify-content-between">
                                <label class="form-label">Phone</label>
                                <i class="bi bi-plus-circle-fill js-add-btn js-add-btn-3"></i>
                              </div>
                              <input class="mt-1 mb-1 form-control rounded" type="tel" placeholder="Enter Phone Number"/>   
                              <div id="new-phone-field-3"></div>                                        
                            </div>
                            <div class="mb-3 mt-3">
                              <div class="d-flex justify-content-between">
                                <label class="form-label">Email</label>
                                <i class="bi bi-plus-circle-fill js-add-btn js-add-btn-4"></i>
                              </div>
                              <input class="mt-1 mb-1 form-control rounded" type="email" placeholder="Enter Email Number"/>   
                              <div id="new-email-field-4"></div>       
                                                                       
                            </div>
                            <div class="mb-3 mt-3">
                              <label class="form-label">Role in the dispute</label>
                              <textarea class="form-control rounded mt-1 mb-1" rows="5" placeholder="Briefly describe role in the dispute"></textarea>
                            </div>
                          </div> 
                        </div>
                </>:""
              }
              {
                cardIndex==2+formData.borrowerDetails.length+formData.coBorrowerDetails.length?<>
                <div className="lr-box-content">
                          <div className="mb-3">
                            <label className="form-label">Driving License Number</label>
                            <input className="mt-1 mb-1 form-control rounded" type="text" placeholder="Enter Driving License Number"/>
                          </div>
                          <div className="mb-3">
                            <label className="form-label">RC Number</label>
                            <input className="mt-1 mb-1 form-control rounded" type="text" placeholder="Enter RC Number"/>
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Vehicle Number</label>
                            <input className="mt-1 mb-1 form-control rounded" type="text" placeholder="Enter Vehicle Number"/>
                          </div>  
                        </div>
                </>:""
              }
            </div>
            <PrevNextButton
              length={cardIndexLength}
              cardIndex={cardIndex}
              onSubmit={handlePrevNextButton}
            />
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default SingleDisputeForm;
