import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './PaymentHistory.css';

const PaymentHistory = (props) => {
  console.log(props)
  const initialYear = `20${props?.historyData[0].key.split('-')[1]}`;
  console.log(initialYear)
  const [currentYear, setCurrentYear] = useState(initialYear);
  const monthsMap = {
    "Jan": "01",
    "Feb": "02",
    "Mar": "03",
    "Apr": "04",
    "May": "05",
    "Jun": "06",
    "Jul": "07",
    "Aug": "08",
    "Sep": "09",
    "Oct": "10",
    "Nov": "11",
    "Dec": "12"
  };
  const getYearsFromHistory = (history) => {
    // Extract the year part from the key property of each object
    const years = history.map(item => {
      const [, year] = item.key.split('-'); // Split the key by '-' and get the year part
      console.log(year)
      return `20${year}`; // Convert the year to '20YY' format
    });
    console.log(years)
    return [...new Set(years)];
  };
  const changeYear = (direction) => {
    const years = getYearsFromHistory(props.historyData).reverse();
    const currentIndex = years.indexOf(currentYear);
    const newIndex = (currentIndex + direction + years.length) % years.length;
    setCurrentYear(years[newIndex]);
  };

  const getPaymentStatusClass = (status) => {
    if (status === "STD") {
      return "tick";
    } else if (status !== "") {
      // const daysLate = parseInt(status, 10);
      if (status == "90+") {
        return "late-red";
      } else {
        return "late-yellow";
      }
    }
    return "";
  };
  
  const renderCalendar = (data) => {
    return (
      <div className="year-section">
        <div className="header">
          <FontAwesomeIcon icon={faArrowLeft} onClick={() => changeYear(-1)} />
          <h3 style={{color:"#4f72c9"}}>Payment History {currentYear}</h3>
          <FontAwesomeIcon icon={faArrowRight} onClick={() => changeYear(1)} />
        </div>
        <div className="months-grid">
          {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map(month => {
            // // console.log(monthsMap[month])
            // {
            //   "key": "05-24",
            //   "PaymentStatus": "STD",
            //   "SuitFiledStatus": "*",
            //   "AssetClassificationStatus": "STD"
            // },
            const payment = data.find(item => item.key.split('-')[0] === monthsMap[month] && `20${item.key.split('-')[1]}` === currentYear);
            console.log(payment)
            return (
              <div key={month} className={`month ${payment ? getPaymentStatusClass(payment.PaymentStatus) : ''}`}>
                <span>{month}</span>
                {payment && payment.PaymentStatus !== '' ? (payment.PaymentStatus === 'STD' ? '✔️' : payment.PaymentStatus) : ''}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  if (!currentYear) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <div className="payment-history">
        {renderCalendar(props.historyData)}
      </div>
    </div>
  );
};

export default PaymentHistory;
