/* eslint-disable react/prop-types */
import React from 'react'

const FirstCard = ({ loan }) => {
    return (
        <div className="card card-new mb-3 mt-3">
            <div className="row justify-content-center align-items-center">
                <div className="col-lg-4 col-lg-4 d-flex justify-content-center align-items-center">
                    <div className="claim-amount text-center">
                        <h3>
                            <strong>Claim Amout</strong>
                        </h3>
                        <p className="ca-2">
                            {loan?.claimAmount || " "}
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 before-divide">
                    <div className="claim-emi-box text-center">
                        <h3>EMI</h3>
                        <div className="row text-center">
                            <>
                                <div className="col-lg-6 start-emi">
                                    <p className="mb-0">
                                        {" "}
                                        {loan?.emiStart || " "}
                                    </p>
                                    <p>
                                        <span>Start EMI</span>
                                    </p>
                                </div>
                            </>
                            <>
                                <div className="col-lg-6 end-emi">
                                    <p className="mb-0">
                                        {" "}
                                        {loan?.emiEnd || " "}
                                    </p>
                                    <p>
                                        <span>End EMI</span>
                                    </p>
                                </div>
                            </>
                        </div>
                        <p className="mb-0 claim-emi-box-note"></p>
                    </div>
                </div>
                <div className="col-lg-4 before-divide s-a-c">
                    <>
                        <div className="seat-of-arbitration text-center">
                            <h2>Seat of Arbitration</h2>
                            <p>
                                {loan?.seat ? loan?.seat : ""}
                            </p>
                        </div>
                    </>

                    <hr className="mt-0" />
                    <div className="seat-of-arbitration text-center">
                        <h2>Arbitration</h2>
                        <p>
                            {loan?.noticeStage
                                ? loan?.noticeStage
                                : ""}
                        </p>
                    </div>
                    <h6 className="dash-per"> </h6>
                </div>
            </div>
        </div>
    )
}

export default FirstCard