import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const AgeModal = () => {
  const theme = useSelector((state) => state.theme.theme);
  const [yearsDifference, setYearsDifference] = useState({});
  const [accounts, setAccounts] = useState([]);
  const calculateDateDifference = (dateString) => {
    const regex = /(\d{2}-\d{2}-\d{4})/;
    const match = dateString?.match(regex);

    if (match) {
      const dateParts = match[0].split("-");
      const date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);

      const now = new Date();
      let years = now.getFullYear() - date.getFullYear();
      let months = now.getMonth() - date.getMonth();
      let days = now.getDate() - date.getDate();

      if (days < 0) {
        months -= 1;
        days += new Date(now.getFullYear(), now.getMonth(), 0).getDate();
      }

      if (months < 0) {
        years -= 1;
        months += 12;
      }

      return { years, months, days };
    }

    return { years: 0, months: 0, days: 0 };
  };
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  const data = useSelector((state) => state.dashboardSlice.data);

  const handleNext = () => {
    if ((currentPage + 1) * itemsPerPage < accounts.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Calculate the current items to display
  const startIndex = currentPage * itemsPerPage;
  const selectedAccounts = accounts?.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  console.log(data);
  useEffect(() => {
    if (data) {
      const filterAccounts1 =
        data?.CIBILDetails?.credit_report.CCRResponse.CIRReportDataLst[0].CIRReportData.RetailAccountDetails.map(
          (item) => ({
            AccountNumber: item.AccountNumber,
            BankName: item.Institution,
            AccountType: item.AccountType,
            Active: item.Open,
            PendingAmount: item.Balance,
            LastPaymentDate: item.LastPaymentDate,
            history: item.History48Months,
          })
        );
      setAccounts(filterAccounts1);
      const filterAccounts =
        data?.CIBILDetails?.credit_report.CCRResponse.CIRReportDataLst[0]
          .CIRReportData.RetailAccountsSummary;
      const extractyears = calculateDateDifference(
        filterAccounts?.OldestAccount
      );
      extractyears.noOfActiveAccounts = filterAccounts?.NoOfActiveAccounts;
      setYearsDifference(extractyears);
    }
  }, [data]);
  return (
    <div
      className="modal fade"
      id="ageModel"
      tabIndex="-1"
      aria-labelledby="ageModel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Age Details</h4>
            <button
              type="button"
              className={`btn-close ${
                theme ? "btn-close-black" : "btn-close-white"
              }`}
              data-bs-dismiss="modal"
            ></button>
          </div>

          <div class="modal-body">
            <div class="card card-new">
              <div class="d-flex payment-header">
                <div class="payment-header-left w-50">
                  <h2 class="mb-0">Medium Impact</h2>
                  <p>
                    Last updated on <strong>13 Jun 2024</strong>
                  </p>
                </div>
                <div class="payment-header-right w-50 text-end  align-items-center">
                  <span class="badge text-bg-success mt-3">
                    {yearsDifference.years > 10 ? "Excellence" : "Fair"}
                  </span>
                </div>
              </div>
              <div class="d-flex mt-2">
                <div class="">
                  <h2 class="mb-0">{yearsDifference.noOfActiveAccounts}</h2>
                  <p class="mb-0">Active Accounts</p>
                </div>
                <div class="ps-5">
                  <h2 class="mb-0">
                    {yearsDifference.years} y {yearsDifference.months} m{" "}
                  </h2>
                  <p class="mb-0">Age of Accounts</p>
                </div>
              </div>
            </div>
            <div class="card card-new text-bg-light ageModal">
              <div class="d-flex payment-header">
                <div style={{ marginRight: "8px", marginTop: "4px" }}>
                  <i
                    className="fas fa-thumbs-up text-info"
                    style={{ fontSize: "30px" }}
                  ></i>
                </div>
                <div class="payment-header-left w-60">
                  <h2 class="p-accounts mb-0 text-info">
                    {" "}
                    {yearsDifference.years > 10
                      ? "High Account Age"
                      : "Average Account Age"}
                  </h2>
                  <p className="mb-0">
                    Good Job ! You have a account for last{" "}
                    <strong>
                      {yearsDifference.years} years {yearsDifference.months}{" "}
                      months
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <h4 className="fz-20">Your Accounts({accounts?.length})</h4>
            {selectedAccounts?.map((item, index) => {
              const isClosed = item.Active !== "Yes";
              return (
                <div
                  className={`d-flex border-bottom pb-3 pt-3 ${
                    isClosed ? "blur" : ""
                  }`}
                >
                  <div class="flex-shrink-0">
                    <img
                      src="https://placehold.co/65x65/EEE/31343C"
                      alt="..."
                    />
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <h4 class="mb-0 fw-600">{item.BankName}</h4>
                    <p class="mb-0 text-muted">
                      {item.AccountType}: {item.AccountNumber}
                    </p>
                    <p class="mb-0 text-muted">
                      Status:{" "}
                      <strong class="text-primary">
                        {item.Active == "Yes" ? "Active" : "closed"}
                      </strong>
                    </p>
                  </div>
                </div>
              );
            })}
            <div className="d-flex justify-content-between mt-4 align-items-center">
              <div>
                <button
                  className="btn btn-primary"
                  onClick={handlePrev}
                  disabled={currentPage === 0}
                >
                  Prev
                </button>
              </div>
              <div>
                {/* Display current slide number */}
                <span className="fs-6">
                  {`Page ${currentPage + 1} of ${Math.ceil(
                    accounts?.length / itemsPerPage
                  )}`}
                </span>
              </div>
              <div>
                <button
                  className="btn btn-primary"
                  onClick={handleNext}
                  disabled={(currentPage + 1) * itemsPerPage >= accounts?.length}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgeModal;
