/* eslint-disable no-unused-vars */
import { React, useState } from "react";
import CsvUploadPage from "./disputeUploadPage/CsvUploadPage1";
import DisputeUploadTablePage from "./disputeUploadTablePage/DisputeUploadTablePage";
import Navbar from "../../components/navbar";
import { useNavigate } from "react-router-dom";
import LoginHeader from "../../components/loginHeader";
import BackButton from "../../components/backButton";
// import BackButton from 'Code/FrontendFiles/calling-module/src/components/backButton.jsx';

const UploadAndTableIntegartionPage = () => {
  const navigate = useNavigate();
  const [refreshData, setRefreshData] = useState(false);

  const handleRefresh = () => {
    setRefreshData((prev) => !prev);
  };

  return (
    <div className="main-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3">
            <div className="left-wrapper">
              {<Navbar />}
              <BackButton link="/dashboard"></BackButton>
              <div className="" id="collapseExample">
                <div className="dot-bg">

                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9">
            <LoginHeader />
            <div className="right-wrapper mt-3">
              <CsvUploadPage key={refreshData} onSuccess={handleRefresh} />
              <hr className="mt-0-1 mb-5" />
              <DisputeUploadTablePage refresh={refreshData} />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default UploadAndTableIntegartionPage;
