/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ToggleCommBtn from "./components/TogglesAndModals/ToggleCommBtn.jsx";
import UseHandleModal from "../Modal/UseHandleModal.jsx";
import http from "../../../services/httpService.js";
import Navbar from "../../../components/navbar.jsx";
import LoginHeader from "../../../components/loginHeader.jsx";
import BackButton from "../../../components/backButton.jsx";
import FirstCard from "./components/Cards/FirstCard.jsx";
import SecondCard from "./components/Cards/SecondCard.jsx";
import ThirdCard from "./components/Cards/ThirdCard.jsx";
import FourthCard from "./components/Cards/FourthCard.jsx";
import Loading from "../../../components/loading.jsx";

const DisputeDataProfilePage = () => {
  const [loan, setLoan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { handleModal2, handleModal3, showLoadingModalOnly } = UseHandleModal();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const loanID = queryParams.get("loanId");

  const fetchLoanDetails = async () => {
    try {
      const response = await http.get2(`/getLoans/${loanID}`);
      setLoan(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error);
      handleModal2("Failed to fetch loan data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loanID) {
      fetchLoanDetails();
    }
  }, [loanID]);

  if (loading) {
    return null;
  }

  if (error) {
    handleModal2("Something Went Wrong!!");
    return null;
  }

  if (!loan) {
    return (
      <div className="container mt-4">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="main-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-3">
              <div className="left-wrapper">
                <Navbar />
                <div className="dot-bg">
                  <BackButton link="/DisputeReporting" />
                </div>
              </div>
            </div>
            <div className="col-xl-9">
              <LoginHeader />
              <div className="right-wrapper mt-3">
                {loan && (
                  <>
                    <div className="main-wrapper">
                      <div className="container-fluid">
                        <div className="row" key={loan?.loanId}>
                          <div className="col-xl">
                            <div className="right-wrapper">
                              <div className="conciliation-application-form">
                                <div className="col-lg-12">
                                  <div className="col-lg-12 d-flex justify-content-end">
                                    <div className="row" key={loan.loanId}>
                                      <ToggleCommBtn loan={loan} />
                                    </div>
                                  </div>
                                  {/* First Card */}
                                  <FirstCard loan={loan} />

                                  {/* Second Card */}
                                  <SecondCard loan={loan} />

                                  {/* Third Card */}
                                  <ThirdCard loan={loan} />

                                  {/* Fourth Card */}
                                  <FourthCard loan={loan} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DisputeDataProfilePage;
