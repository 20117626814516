import React from "react";
import arrow1 from "../images/arrow-prev.svg";
import arrow1Yellow from "../dark-images/arrow-prev-white.svg";
import { useSelector } from "react-redux";
import useLanguage from "../local/saveLanguage";

const PrevButton = ({ btnName, onSubmit, disabledBtn, type}) => {
  const translate = useLanguage.getLang();
  const theme = useSelector((state) => state.theme.theme);
  return (
    <button
      type="submit"
      className="butmim2 ms-auto"
      id="prevBtn"
      onClick={()=>onSubmit(type)}
      disabled={disabledBtn}
    >
        
      <div className="buttoncircle1 endh centrex">
        <img
          className="arrow1 btn-arrow-left"
          id="btn-arrow"
          src={theme ? arrow1 : arrow1Yellow}
          alt="Arrow"
        />
      </div>
      <h4 className="blue" data-translate="tran-9">
        {translate?.[btnName] ?? btnName}
      </h4>
    </button>
  );
};

export default PrevButton;
