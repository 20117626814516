import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const AccountModal = (props) => {
  const theme = useSelector((state)=>state.theme.theme);
  const [accounts,setAccounts] = useState([]);
  const data = useSelector(state => state.dashboardSlice.data);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;
  const TimelyPayment = (data) =>{
  let countSTD = 0;
  let totalPaymentStatus = 0;
  data.forEach(record => {
    if (record.PaymentStatus) {
      totalPaymentStatus++;
      if (record.PaymentStatus === "STD" ||record.PaymentStatus === "000") {
        countSTD++;
      }
    }
  });
  return {
    totalPaymentStatus,
    countSTD
  };
  }
  const handleNext = () => {
    if ((currentPage + 1) * itemsPerPage < accounts?.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

    // Calculate the current items to display
    const startIndex = currentPage * itemsPerPage;
    const selectedAccounts = accounts?.slice(
      startIndex,
      startIndex + itemsPerPage
    );

  useEffect(() => {
    if(Object.values(data).length > 0){
        const filterAccounts = data.CIBILDetails.credit_report.CCRResponse.CIRReportDataLst[0].CIRReportData.RetailAccountDetails.map((item)=>({
            AccountNumber:item.AccountNumber,
            BankName:item.Institution,
            AccountType:item.AccountType,
            Active:item.Open,
            PendingAmount:item.Balance,
            LastPaymentDate:item.LastPaymentDate,
            history:item.History48Months
        }))
        setAccounts(filterAccounts);
      }
  }, [data]);
  const latePayment = props?.PaymentStatus.totalcount - props?.PaymentStatus.paidcount;
  const percent = props?.PaymentStatus.percent;
  console.log(accounts)
  return (
    <div
      className="modal fade"
      id="accountsmodal"
      tabIndex="-1"
      aria-labelledby="accountsmodal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Account Details</h4>
            <button
              type="button"
              className={`btn-close ${theme ? 'btn-close-black' : 'btn-close-white'}`}
              data-bs-dismiss="modal"
            ></button>
          </div>

          <div class="modal-body">
            <div class="card card-new">
              <div class="d-flex payment-header">
                <div class="payment-header-left w-50">
                  <h2 class="mb-0">{percent > 80 ?  'High' : percent > 60 ? 'Medium' : 'Low'} Impact</h2>
                  <p className="p-accounts">
                    {/* Last updated on <strong>13 Jun 2024</strong> */}
                  </p>
                </div>
                <div class="payment-header-right w-50 text-end  align-items-center">
                  <span class="badge text-bg-success mt-3">{percent > 80 ? 'Excellence' : percent > 60 ? 'Good' : percent > 50  ? 'Average' : 'Bad'}</span>
                </div>
              </div>
              <div class="d-flex mt-2">
                <div class="">
                  <h2 class="mb-0">{latePayment}</h2>
                  <p class="mb-0">Late Payment</p>
                </div>
                <div class="ps-5">
                  <h2 class="mb-0">{percent.toFixed(2)}%</h2>
                  <p class="mb-0">Payment on time</p>
                </div>
              </div>
            </div>
            <h4 className="fz-20">Your Accounts</h4>
            {selectedAccounts?.map((item,index)=> {
              const isClosed = item.Active !== "Yes";
              const { totalPaymentStatus, countSTD } = TimelyPayment(item.history);
              return (
              <div className={`d-flex border-bottom pb-3 pt-3 ${isClosed ? 'blur' : ''}`}>
              <div class="flex-shrink-0">
                <img src="https://placehold.co/80x80/EEE/31343C" alt="..." />
              </div>
              <div class="flex-grow-1 ms-3">
                <h4 class="mb-0">{item.BankName}</h4>
                <p class="mb-0 text-muted">{item.AccountType}: {item.AccountNumber}</p>
                <p class="text-muted mb-0">
                  Timily Payment: <strong class="text-primary">{countSTD}/{totalPaymentStatus}</strong>
                </p>
                <p class="mb-0 text-muted">
                  Status: <strong class="text-primary">{item.Active == "Yes" ? "Active" : "closed"}</strong>
                </p>
              </div>
            </div>
              );
            })}    
             <div className="d-flex justify-content-between mt-4 align-items-center">
              <div>
                <button
                  className="btn btn-primary"
                  onClick={handlePrev}
                  disabled={currentPage === 0}
                >
                  Prev
                </button>
              </div>
              <div>
                {/* Display current slide number */}
                <span className="fs-6">
                  {`Page ${currentPage + 1} of ${Math.ceil(
                    accounts?.length / itemsPerPage
                  )}`}
                </span>
              </div>
              <div>
                <button
                  className="btn btn-primary"
                  onClick={handleNext}
                  disabled={(currentPage + 1) * itemsPerPage >= accounts.length}
                >
                  Next
                </button>
              </div>
            </div> 
            {/* <div class="d-flex border-bottom pb-3 pt-3">
              <div class="flex-shrink-0">
                <img src="https://placehold.co/80x80/EEE/31343C" alt="..." />
              </div>
              <div class="flex-grow-1 ms-3">
                <h4 class="mb-0">SBI</h4>
                <p class="mb-0 text-muted">Home Loan: ****1867</p>
                <p class="mb-0 text-muted">
                  Status: <strong class="text-muted">Closed</strong>
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountModal;
