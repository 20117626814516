import React, { useEffect, useState } from "react";
import PaymentHistory from "../PaymentMore/PaymentCalender";
import { useSelector } from "react-redux";

const PaymentModal = () => {
  const theme = useSelector((state) => state.theme.theme);
  console.log(theme)

  const [currentIndex, setCurrentIndex] = useState(0);
  const data = useSelector((state) => state.dashboardSlice.data);
  const filterAccounts =
    data?.CIBILDetails?.credit_report.CCRResponse.CIRReportDataLst[0].CIRReportData.RetailAccountDetails.map(
      (item) => ({
        AccountNumber: item.AccountNumber,
        BankName: item.Institution,
        AccountType: item.AccountType,
        Active: item.Open,
        PendingAmount: item.Balance,
        LastPaymentDate: item.LastPaymentDate,
      })
    );
  const PaymentDatas =
    data?.CIBILDetails?.credit_report.CCRResponse.CIRReportDataLst[0].CIRReportData.RetailAccountDetails.filter(
      (item) => item.Open == "Yes"
    ).map((item) => ({
      AccountNumber: item.AccountNumber,
      tenure: item.RepaymentTenure,
      emi: item.InstallmentAmount,
      BankName: item.Institution,
      AccountType: item.AccountType,
      Active: item.Open,
      PendingAmount: item.Balance,
      LastPaymentDate: item.LastPaymentDate,
      issueOn: item.DateOpened,
      TotalLoan: item.SanctionAmount,
      interestRate: item.InterestRate,
      history: item.History48Months,
    }));

  const handleNext = () => {
    if (currentIndex < PaymentDatas.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const paymentData =
    PaymentDatas && PaymentDatas.length > 0 ? PaymentDatas[currentIndex] : null;
    if (!paymentData) {
      return <div>Loading...</div>;
    }
  return (
    <div
      className="modal fade"
      id="paymentmodal"
      tabIndex="-1"
      aria-labelledby="paymentModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="paymentModalLabel">
              Payment Details
            </h4>
            <button
              type="button"
              className={`btn-close ${theme ? "btn-close-black" : "btn-close-white"}`}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {/* {PaymentDatas?.map((paymentData, index) => ( */}
            <div className="loan-summary">
              <div className="row border-bottom pb-3">
                <div className="col-lg-8">
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <img
                        src="https://placehold.co/70x70/EEE/31343C"
                        alt="..."
                      />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h4 className="mb-0 fw-600">{paymentData?.BankName}</h4>
                      <p className="mb-0 text-muted">
                        {paymentData?.loanType}: {paymentData?.AccountNumber}
                      </p>
                      <p className="text-muted mb-0">
                        Interest Rate:{" "}
                        <strong className="text-primary">
                          {paymentData?.interestRate}
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 text-lg-end pt-2 pt-lg-0">
                  <h5 className="mb-0">{paymentData?.AccountType}</h5>
                  <p className="mb-0 text-muted">
                    Issued on: {paymentData?.issueOn}
                  </p>
                  <button type="button" className="btn btn-primary btn-sm">
                    {paymentData?.Active == "Yes" ? "Active" : "closed"}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3 mb-3">
                  <h4 className="fw-600 fz-20">Loan Particulars</h4>
                </div>
                <div className="col-lg-6">
                  <h5 className="text-muted">Outstanding</h5>
                  <p>{paymentData?.PendingAmount}</p>
                </div>
                <div className="col-lg-6">
                  <h5 className="text-muted">Loan Amount</h5>
                  <p>{paymentData?.TotalLoan}</p>
                </div>
                <div className="col-lg-6">
                  <h5 className="text-muted">EMI Amount</h5>
                  <p>{paymentData?.emi}</p>
                </div>
                <div className="col-lg-6">
                  <h5 className="text-muted">Tenure</h5>
                  <p>{paymentData?.tenure} Months</p>
                </div>
                <div className="col-lg-12 mt-3">
                  <p className="text-muted">
                    Last Updated on{" "}
                    <strong>{paymentData?.LastPaymentDate}</strong> as reported
                    by <strong> CIBIL bureau </strong>
                  </p>
                </div>
              </div>
              <PaymentHistory historyData = {paymentData?.history}/>
            </div>
            {/* ))} */}
            <div className="d-flex justify-content-between">
              <div>
                <button className="btn btn-primary" onClick={handlePrev} disabled={currentIndex === 0}>
                  Prev
                </button>
              </div>
              <div>
                <button className="btn btn-primary" onClick={handleNext}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
