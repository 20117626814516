/* eslint-disable react/prop-types */
import React from "react";

const FourthCard = ({ loan }) => {
  return (
    <div>
      {" "}
      {[
        loan?.disputeResolutionPaperID,
        loan?.associateName,
        loan?.caseID,
        loan?.refNo,
        loan?.pinCode,
        loan?.state,
        loan?.zone,
        loan?.dpd,
        loan?.meetingDate,
        loan?.noticeDate,
      ].some((field) => field && field !== "N/A") ? (
        <div className="card card-new">
          {/* Additional Detail Section */}
          <>
            <h2 className="mb-3 mt-3">Additional Details</h2>
            <div className="row">
              <>
                <div className="col-lg-4">
                  <p>
                    <strong>Dispute Resolution Paper ID:</strong>{" "}
                    {loan?.disputeResolutionPaperID || ""}
                  </p>
                </div>
              </>

              <>
                <div className="col-lg-4">
                  <p>
                    <strong>Associate Name:</strong> {loan?.associateName || ""}
                  </p>
                </div>
              </>

              <>
                <div className="col-lg-4">
                  <p>
                    <strong>Case ID:</strong> {loan?.caseID || ""}
                  </p>
                </div>
              </>

              <>
                <div className="col-lg-4">
                  <p>
                    <strong>Reference Number:</strong> {loan?.refNo || ""}
                  </p>
                </div>
              </>

              <>
                <div className="col-lg-4">
                  <p>
                    <strong>Pin Code: </strong> {loan?.pinCode || ""}
                  </p>
                </div>
              </>

              <>
                <div className="col-lg-4">
                  <p>
                    <strong>State:</strong> {loan?.state || ""}
                  </p>
                </div>
              </>

              <>
                <div className="col-lg-4">
                  <p>
                    <strong>Zone:</strong> {loan?.zone || ""}
                  </p>
                </div>
              </>

              <>
                <div className="col-lg-4">
                  <p>
                    <strong>DPD:</strong> {loan?.dpd || ""}
                  </p>
                </div>
              </>

              <>
                <div className="col-lg-4">
                  <p>
                    <strong>Meeting Date:</strong> {loan?.meetingDate || ""}
                  </p>
                </div>
              </>

              <>
                <div className="col-lg-4">
                  <p>
                    <strong>Notice Date</strong> {loan?.noticeDate || ""}
                  </p>
                </div>
              </>
            </div>
          </>
        </div>
      ) : null}
    </div>
  );
};

export default FourthCard;
