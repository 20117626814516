import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the components you need
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const BarChart = (props) => {
  const theme = useSelector((state) => state.theme.theme);
  const user = props?.user;
  // console.log(user);
  const gstArray = props?.user?.GSTDetails;
  const combinedTurnOver = gstArray.reduce((acc, gst) => {
    gst.gstTurnOver5year.forEach(({ pan_estimated_total, year }) => {
      if (!acc[year]) {
        acc[year] = 0;
      }
      acc[year] += parseFloat(pan_estimated_total);
    });
    return acc;
  }, {});
  
  const resultArray = Object.keys(combinedTurnOver).map((year) => ({
    year,
    totalEstimated: combinedTurnOver[year],
  }));
  
  // console.log(resultArray);
  const labels = resultArray.map(item => item.year);
  const dataValues = resultArray.map(item => parseFloat(item.totalEstimated));

// Create the data object for Chart.js
const data = {
  labels: labels,
  datasets: [
    {
      label: "Company Turnover Each Year",
      backgroundColor: `${theme ? "#2f4b7c" : "#ffe665"}`,
      borderColor: "rgb(255, 99, 132)",
      data: dataValues,
    },
  ],
};
const options = {
  responsive: true,
  maintainAspectRatio: false, // Disables the default aspect ratio so it can adjust to the container size
  scales: {
    x: {
      ticks: {
        autoSkip: false, // Ensures all labels show on smaller screens
      },
    },
  },
};

  return (
    <div style={{ width: "100%", height: "400px" }}>
      <Bar data={data} options={options}/>
    </div>
  );
};
export default BarChart;