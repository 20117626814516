import React from "react";
import { useNavigate } from "react-router-dom";

const BackButton = ({ link }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
  };

  return (
    <button
      type="button"
      className="btn btn-light btn-sm back-btn mb-3"
      onClick={handleClick}
    >
      <i className="bi bi-arrow-left"></i> Back
    </button>
  );
};

export default BackButton;
