import PrevButton from "./prevButton";
import NextButton from "./nextButton";


function PrevNextButton({length, onSubmit, cardIndex}) {
    console.log(length)
    return (
      <>
        <div className="d-flex justify-content-between next-prev-btn ">
          <div className="me-4">
            {cardIndex > 0 ? (
              <PrevButton
                btnName="Prev"
                onSubmit={onSubmit}
                type="prev"
              />
            ) : (
              <></>
            )}
          </div>
          <div className="">
            {cardIndex < length ? (
              <NextButton
                btnName="Next"
                onSubmit={onSubmit}
                type="next"
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
  };

export default PrevNextButton;