import React, { useState } from "react";
import { useSelector } from "react-redux";
import CryptoJS from 'crypto-js';
import axios from "axios";

const ShareModal = ({ shareClick, setShareClick }) => {
  const theme = useSelector(state => state.theme.theme);
  const [shareOption, setShareOption] = useState("");
  const [inputValue, setInputValue] = useState("");
  const data = useSelector(state => state.dashboardSlice.data);
  const handleSend = async () => {
    const secretKey = "PrivatecourtProfileData"; // Use a secure key
    // Get the current time and expiration time (1 hour from now)
    const currentTime = new Date().getTime();
    const expirationTime = currentTime + (7 * 24 * 60 * 60 * 1000);
    // Combine UID with the expiration time
    const dataToEncrypt = JSON.stringify({
      UID: data.UID,
      exp: expirationTime,
    });
    const encryptedData = CryptoJS.AES.encrypt(dataToEncrypt, secretKey).toString();
    const profileLink = `https://share.pvtcourt.com/UserProfile?data=${encodeURIComponent(encryptedData)}`;
    if (shareOption === "email") {
      await axios.post("http://localhost:3004/api/shareprofile",{profilelink:profileLink,type:shareOption,inputvalue:inputValue}).then((res)=>{
        console.log(res.data);
      })
      .catch((err)=>{
        console.log(err);
      })
    } else if (shareOption === "whatsapp") {
      await axios.post("http://localhost:3004/api/shareprofile",{profilelink:profileLink,type:shareOption,inputvalue:inputValue}).then((res)=>{
        console.log(res.data);
      })
      .catch((err)=>{
        console.log(err);
      })
    }
  };
  return (
    <div
      className={`modal fade ${shareClick ? "show d-block" : ""}`}
      id="ShareModal"
      tabIndex="-1"
      aria-labelledby="ShareModalLabel"
      aria-hidden={!shareClick}
      style={{ display: shareClick ? "block" : "none" }} // Ensure modal display is managed
    >
      <div className="modal-dialog modal-lg" style={{ height: "20rem" }}>
        <div className="modal-content" style={{ height: "100%" }}>
          <div className="modal-header">
            <h4 className="modal-title" id="ShareModalLabel">
              Share Your Details
            </h4>
            <button
              type="button"
              className={`btn-close ${theme ? "btn-close-black" : "btn-close-white"}`}
              onClick={() => setShareClick(false)} // Close the modal
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body "> 
          <div className="mb-3">
              <label htmlFor="shareOptionSelect" className="form-label">
                Choose how to share:
              </label>
              <select
                id="shareOptionSelect"
                className="form-select"
                value={shareOption}
                onChange={(e) => setShareOption(e.target.value)}
              >
                <option value="">Select an option</option>
                <option value="email">Email</option>
                <option value="whatsapp">WhatsApp</option>
              </select>
              </div>
              {shareOption === "email" && (
              <div className="mb-3">
                <label htmlFor="emailInput" className="form-label">
                  Enter recipient's email:
                </label>
                <input
                  type="email"
                  id="emailInput"
                  className="form-control"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="example@email.com"
                />
              </div>
            )}

            {shareOption === "whatsapp" && (
              <div className="mb-3">
                <label htmlFor="whatsappInput" className="form-label">
                  Enter recipient's WhatsApp number:
                </label>
                <input
                  type="tel"
                  id="whatsappInput"
                  className="form-control"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="+1234567890"
                />
              </div>
            )}

            {/* Send Button */}
            {shareOption && (
              <div className="mb-3">
              <button
                className="btn btn-primary mb-3"
                onClick={handleSend}
                disabled={!inputValue}
              >
                Send
              </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
