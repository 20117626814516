/* eslint-disable react/prop-types */
import React from "react";

const ThirdCard = ({ loan }) => {
  return (
    <div>
      {" "}
      {[loan?.bankAccountNo, loan?.ifscCode].some(
        (field) => field && field !== "N/A"
      ) ? (
        <div className="card card-new">
          {/* Bank Detail Section */}
          <>
            <h2 className="mb-3 mt-3">Bank Details</h2>
            <div className="row">
              <>
                <div className="col-lg-4">
                  <p>
                    <strong>Account Number: </strong>{" "}
                    {loan?.bankAccountNo || ""}
                  </p>
                </div>
              </>
              <>
                {" "}
                <div className="col-lg-4">
                  <p>
                    <strong>IFSC Code: </strong> {loan?.ifscCode || ""}
                  </p>
                </div>
              </>
            </div>
          </>

          {/* Claiments Detail Section */}
          <>
            <hr className="mt-0 mb-1" />
            <h2 className="mb-3 mt-3">Claiment Details</h2>
            <div className="row">
              <>
                <div className="col-lg-4">
                  <p>
                    <strong>Company:</strong> {loan?.claimantCompany || ""}
                  </p>
                </div>
              </>
              <>
                <div className="col-lg-4">
                  <p>
                    <strong>Address: </strong>{" "}
                    {loan?.claimantCompanyAddress || ""}
                  </p>
                </div>
              </>
              <>
                {" "}
                <div className="col-lg-4">
                  <p>
                    <strong>Email: </strong> {loan?.claimantEmail || ""}
                  </p>
                </div>
              </>
            </div>
          </>
        </div>
      ) : null}
    </div>
  );
};

export default ThirdCard;
