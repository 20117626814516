import React, { useEffect } from "react";
import { useDetectAdBlock } from "adblock-detect-react";
import styled from "styled-components";

// Styled components for the overlay and modal
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;



const Modal = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #333;
`;

const Message = styled.p`
  margin-bottom: 1.5rem;
  font-size: 1rem;
  color: #666;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: #0056b3;
  }
`;

const AdBlockerWarning = () => {
  const adBlockDetected = useDetectAdBlock();

  useEffect(() => {
    if (adBlockDetected) {
      console.log("Ad Blocker detected");
    }
  }, [adBlockDetected]);

  if (!adBlockDetected) {
    return null;
  }

  return (
    <Overlay>
      <Modal>
        <Title>Ad Blocker Detected</Title>
        <Message>
          We've detected that you're using an ad blocker. Please disable it to
          support us and ensure that all features on our website work correctly.
        </Message>
        <Button onClick={() => window.location.reload()}>I have disabled it</Button>
      </Modal>
    </Overlay>
  );
};

export default AdBlockerWarning;
