
import React, { useEffect, useState } from "react";
import { Suspense, lazy } from "react";
import ReactDOM from "react-dom"; 
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/loginPage/login";
import auth from "./services/authService";
import loadingIcon from "./asset/loading3.gif";
// import NewUserDashboard from "./pages/dashboardPage/dashboard";
// import Dispute from "./pages/disputePage/Dispute";
// import FooterComponent from"./components/footerComponent"
import AuthRoute from "./components/authRoute";
import AdBlockerWarning from "./components/adBlockerWarning";
// import NBFCCompanyClientVerify from "./pages/NbfcCompanyVerifyPage/nbfcCompanyVerify";
import FooterComponent from "../src/pages/footerPage/footerComponent";
import Logout from "./components/logout";
import AdminPanelNBCF from "./pages/adminPage/adminPanelNBFC";
import { useLocation, useNavigate } from "react-router-dom";
import UserProfile from "./pages/userProfile/UserProfile";
import UploadAndTableIntegartionPage from "./pages/bulkDisputeUploadPage/UploadAndTableIntegrationPage";
import DisputeUploadTablePage from "./pages/bulkDisputeUploadPage/disputeUploadTablePage/DisputeUploadTablePage";
import DisputeUploadReportPage from "./pages/bulkDisputeUploadPage/disputeUploadReportPage/DisputeUploadReportPage";
// import { UserInputPage } from "./pages/webPageUserInputsPanelPage/UserInputPage";
import DisputeDataProfilePage from "./pages/bulkDisputeUploadPage/disputeDataProfilePage/DisputeDataProfilePage";
// import BillDashboard from "./pages/billPage/BillDashboard";
import SingleDispute from "./pages/singleDispute/singleDispute";
// import Loading from "./components/loading";
const NewUserDashboard =lazy(() => import('./pages/dashboardPage/dashboard'));
const Dispute =lazy(()=>import('./pages/disputePage/Dispute'));
const NBFCVerify= lazy(()=>import('./pages/NbfcVerifyPage/nbfcVerify'));

// const  AuthRoute =lazy(()=>import("./components/authRoute"));
// const AdBlockerWarning=lazy(()=>import("./components/adBlockerWarning"));
const NBFCCompanyClientVerify= lazy(()=>import("./pages/NbfcCompanyVerifyPage/nbfcCompanyVerify"));
// const FooterComponent = lazy(()=>("./components/footerComponent"))
// const Logout=lazy(()=>import("./components/logout"));
// const AdminPanelNBCF =lazy(()=>import("./pages/adminPage/adminPanelNBFC"));

function Loading() {
  return (
    <div className="main-wrapper d-flex justify-content-center align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-lg d-flex justify-content-center align-items-center"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
          >
            <img
              src={loadingIcon}
              alt="loading image"
              style={{
                marginTop: "40vh",
                backgroundColor: "rgba(255, 255, 255, 0.2)",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function App() {
  const theme = useSelector((state) => state.theme.theme);
  const [user, setUser] = useState(auth.getUser().UserLogin);
  const location = useLocation();
  const navigate = useNavigate();

  function getQueryParameter(parameterName) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(parameterName);
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenValue = getQueryParameter("token");
    if (tokenValue) {
      auth.login(tokenValue);
      navigate("/");
    }
  }, [location]);

  useEffect(() => {
    const importThemeStyle = async () => {
      const styleSheetId = "themeStyleSheet";
      const existingStyleSheet = document.getElementById(styleSheetId);
      if (existingStyleSheet) {
        existingStyleSheet.remove();
      }
      const link = document.createElement("link");
      link.id = styleSheetId;
      link.rel = "stylesheet";
      link.href = theme ? "./style.css" : "./style2.css";
      document.head.appendChild(link);
    };

    importThemeStyle();
  }, [theme]);

  // const Loading=<>Loading...</>

  return (
    // <>
    //   <Routes>
    //     <Route path="/login" element={<Login />} />
    //     <Route path='/dashboard' element={<NewUserDashboard/>}/>
    //     <Route path='/verifyClient' element={<NBFCVerify/>}/>
    //     <Route path='/dispute' element={<Dispute/>}/>
    //     <Route path='/' element={<Login/>}/>
    //   </Routes>
    // </>
    <>
      <AdBlockerWarning />
      <Routes>
        {/* <Route path="/login" element={<Login />} /> */}
            <Route path="/logout" element={<Suspense fallback={Loading}><Logout /></Suspense>} />
        <Route
          path="/"
          element={
            <Suspense fallback={Loading}><AuthRoute>
              <Suspense fallback={Loading}><NewUserDashboard /></Suspense>
            </AuthRoute></Suspense>
          }
        />
        {/* Protect the following routes */}
        <Route
          path="/dashboard"
          element={
            <Suspense fallback={Loading}><AuthRoute>
            <Suspense fallback={Loading}><NewUserDashboard /></Suspense>
          </AuthRoute></Suspense>
          }
        />
        <Route
          path="/verifyClient"
          element={
            <Suspense fallback={Loading}><AuthRoute>
              <Suspense fallback={Loading}><NBFCVerify /></Suspense>
            </AuthRoute></Suspense>
          }
        />
        <Route
          path="/verifyCompanyClient"
          element={
            <AuthRoute>
              <NBFCCompanyClientVerify />
            </AuthRoute>
          }
        />
        <Route
          path="/dispute"
          element={
            <Suspense fallback={Loading}><AuthRoute>
              <Suspense fallback={Loading}><Dispute /></Suspense>
            </AuthRoute></Suspense>
          }
        />
        <Route
          path="/userProfile"
          element={
            <Suspense fallback={Loading}><AuthRoute>
              <Suspense fallback={Loading}><UserProfile /></Suspense>
            </AuthRoute></Suspense>
          }
        />
        {/* ////Ayush */}
          <Route
          path="/bulkUpload"
          element={
            <Suspense fallback={Loading}><AuthRoute>
              <Suspense fallback={Loading}><UploadAndTableIntegartionPage /></Suspense>
            </AuthRoute></Suspense>
          }
        />

        <Route
          path="/disputeReporting"
          element={
            <Suspense fallback={Loading}><AuthRoute>
              <Suspense fallback={Loading}><DisputeUploadReportPage /></Suspense>
            </AuthRoute></Suspense>
          }
        />

        <Route
          path="/DisputeUserProfile"
          element={
            <AuthRoute>
              <DisputeDataProfilePage />
            </AuthRoute>
          }
        />
        <Route
          path="/singleDispute"
          element={
            <AuthRoute>
              <SingleDispute />
            </AuthRoute>
          }
        />

        {/* <Route
          path="/generateBill"
          element={
            <AuthRoute>
              <BillDashboard />
            </AuthRoute>
          
        />
                    {/* Ayush *
        {/* <Route
          path="/AdminPanel"
          element={
            <AuthRoute>
              <AdminPanelNBCF />
            </AuthRoute>
          }
        /> */}
      </Routes>
      <AuthRoute>
        <FooterComponent />
      </AuthRoute> 
    </>
  );
}

export default App;