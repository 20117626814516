/* eslint-disable react/prop-types */
import React from "react";

const SecondCard = ({ loan }) => {
  return (
    <div className="card card-new">
      {/* LoanID and Agremeent Detail Section */}
      <>
        <div className="row mt-2">
          <>
            <div className="col-lg-4">
              <p>
                <strong>Loan ID:</strong> {loan?.loanId || " "}
              </p>
            </div>
          </>
          <>
            <div className="col-lg-4">
              <p>
                <strong>Agreement No: </strong> {loan?.agreementNumber || " "}
              </p>
            </div>
          </>
        </div>
      </>

      {/* Borrower Section */}
      <>
        <hr className="mt-0 mb-1" />
        <h2 className="mb-3 mt-3">Borrower</h2>
        <div className="row">
          <>
            <div className="col-lg-4">
              <p>
                <strong>Name: </strong>
                {loan?.borrowerName || " "}
              </p>
            </div>
          </>

          <>
            {" "}
            <div className="col-lg-4">
              <p>
                <strong>Email: </strong>{" "}
                {loan?.borrowerEmail ? loan?.borrowerEmail : ""}
              </p>
            </div>
          </>

          <>
            <div className="col-lg-4">
              <p>
                <strong>Phone: </strong>{" "}
                {loan?.borrowerPhone ? loan.borrowerPhone : ""}
              </p>
            </div>
          </>

          <>
            <div className="col-lg-4">
              <p>
                <strong>Pan: </strong> {loan?.borrowerPANNumber || ""}
              </p>
            </div>
          </>

          <>
            <div className="col-lg-4">
              <p>
                <strong>Address: </strong> {loan?.borrowerAddress || ""}
              </p>
            </div>
          </>
        </div>
      </>

      {/* Co-Borrower Section */}
      <>
        <hr className="mt-0 mb-1" />
        <h2 className="mb-3 mt-3">Co Borrower</h2>
        <div className="row">
          <div className="col-lg-4">
            <p>
              <strong>Name: </strong> {loan?.coBorrowerName || ""}
            </p>
          </div>

          <>
            <div className="col-lg-4">
              <p>
                <strong>Email: </strong> {loan?.coBorrowerEmail || ""}
              </p>
            </div>
          </>

          <>
            <div className="col-lg-4">
              <p>
                <strong>Phone: </strong> {loan?.coBorrowerPhone || ""}
              </p>
            </div>
          </>

          <>
            <div className="col-lg-4">
              <p>
                <strong>Pan: </strong> {loan?.coBorrowerPANNumber || ""}
              </p>
            </div>
          </>

          <>
            <div className="col-lg-4">
              <p>
                <strong>Address: </strong> {loan?.coBorrowerAddress || ""}
              </p>
            </div>
          </>
        </div>
      </>

      {/* GST-Number and CIN Section */}
      {/* {[loan?.gstNumber, loan?.cin].some(
                                        (field) => field && field !== "N/A"
                                    ) ? ( */}
      <div className="gst-cin-number">
        <>
          <hr className="mt-0" />
          <div className="row">
            <div className="col-lg-12">
              <p>
                <strong>GST Number: </strong> {loan?.gstNumber || ""}
              </p>
            </div>
            <div className="col-lg-12">
              <p>
                <strong>CIN: </strong>
                {loan?.cin || ""}
              </p>
            </div>
          </div>{" "}
        </>
      </div>
      {/* ) : null} */}

      {/* Loan Details Section */}
      {/* {[
                                        loan?.loanType,
                                        loan?.interest,
                                        loan?.emi,
                                        loan?.totalLoan,
                                        loan?.principleOutstanding,
                                        loan?.minimumSettlementAmount,
                                        loan?.maximumSettlementAmount,
                                    ].some((field) => field && field !== "N/A") ? ( */}
      <div className="loan-Details">
        <>
          <hr className="mt-0 mb-1" />
          <h2 className="mb-3 mt-3">Loan Details</h2>
          <div className="row">
            <>
              <div className="col-lg-4">
                <p>
                  <strong>Loan Type: </strong> {loan?.loanType || ""}
                </p>
              </div>
            </>

            <>
              {" "}
              <div className="col-lg-4">
                <p>
                  <strong>Interest: </strong> {loan?.interest || ""}
                </p>
              </div>
            </>

            <>
              <div className="col-lg-4">
                <p>
                  <strong>EMI: </strong> {loan?.emi || ""}
                </p>
              </div>
            </>

            <>
              <div className="col-lg-4">
                <p>
                  <strong>Total Loan: </strong> {loan?.totalLoan || ""}
                </p>
              </div>
            </>

            <>
              <div className="col-lg-4">
                <p>
                  <strong>Principle Outstanding: </strong>{" "}
                  {loan?.principleOutstanding || ""}
                </p>
              </div>
            </>

            <>
              <div className="col-lg-4">
                <p>
                  <strong>Min Settlement: </strong>{" "}
                  {loan?.minimumSettlementAmount || ""}
                </p>
              </div>
            </>

            <>
              <div className="col-lg-4">
                <p>
                  <strong>Max Settlement: </strong>{" "}
                  {loan?.maximumSettlementAmount || ""}
                </p>
              </div>
            </>
          </div>
        </>
      </div>
      {/* ) : null} */}

      {/* Vehicle Details Section */}
      {/* {(loan?.vehicleNo && loan?.vehicleNo !== "N/A") ||
                                        (loan?.rcNo && loan?.rcNo !== "N/A") ||
                                        (loan?.drivingLicenceNo &&
                                            loan?.drivingLicenceNo !== "N/A") ? ( */}
      <div className="vehicle-details">
        <>
          <hr className="mt-0 mb-1" />
          <h2 className="mb-3 mt-3">Vehicle Details</h2>
          <div className="row">
            {/* Vehicle Number */}
            <div className="col-lg-4">
              <p>
                <strong>Vehicle Number: </strong> {loan?.vehicleNo || ""}
              </p>
            </div>

            {/* RC Number */}
            <div className="col-lg-4">
              <p>
                <strong>RC Number: </strong> {loan?.rcNo || ""}
              </p>
            </div>

            {/* Driving License */}
            <div className="col-lg-4">
              <p>
                <strong>Driving License: </strong>{" "}
                {loan?.drivingLicenceNo || ""}
              </p>
            </div>
          </div>
        </>
      </div>
      {/* ) : null} */}
    </div>
  );
};

export default SecondCard;
